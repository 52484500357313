<template>
  <span />
</template>

<script>
export default {
  inheritAttrs: false,
  data () {
    return {
      dataDocumentEmit: this.$nextTickDedup(this.emitDocument)
    }
  },
  methods: {
    emitDocument () {
      const doc = { type: 'delete' }
      if (!this.lodash.isEqual(this.document, doc)) {
        this.$emit('update:document', this.lodash.cloneDeep(doc))
      }
    }
  },
  props: {
    document: Object
  },
  watch: {
    document: {
      handler () {
        this.dataDocumentEmit()
      },
      immediate: true
    }
  }
}
</script>
